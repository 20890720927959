import styles from "./TopMenuItem.module.css";

const TopMenuItem= ({ text, barActive, onClick, disabled }) => {
  
  const handleClick = () => {
    if (!disabled) onClick();
  };

  return (
    <div className={styles.topMenuMenuActive} onClick={handleClick}>
      <div className={styles.text}>
        {text}
      </div>
      <img
        className={`${styles.barActiveIcon} ${barActive ? 'active' : ''}`}
        alt=""
        src="/bar-active2.svg"
        style={{ opacity: barActive ? 1 : 0 }}
      />
    </div>
  );
};


export default TopMenuItem;
