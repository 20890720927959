import styles from './Card_Tab_Booking_Events.module.css';
import TimelineContainer from "../TimelineContainer";

const isUniqueEvent = (event, index, array) => {
  return !array.some((e, idx) => {
    return idx < index && e.timestamp === event.timestamp && 
           e.action === event.action && e.shipmentStatus === event.shipmentStatus && 
           e.source === event.source;
  });
};

const Card_Tab_Booking_Events = ({ data }) => { 
  const uniqueData = data.filter(isUniqueEvent);
  
  return (
    <div className={styles.content}>
      {uniqueData.map((event, index) => (
        <TimelineContainer
          key={index}
          timestamp={event.timestamp}
          action={event.action}
          shipmentStatus={event.shipmentStatus}
          sourceTimestamp={event.source}
          showTimelineWithoutIconTim
        />
      ))}
      <div className={styles.text}>
        <div className={styles.iconsClockO1} />
        <div className={styles.date2}>No other events to display from the last 14 days</div>
      </div>
    </div>
  );
};

export default Card_Tab_Booking_Events;
