import styles from "./Alert.module.css";

const Alert = ({
  timestamp,
  event,
  details,
  expected,
  estimated,
  resolved
}) => {
  return (
    <div className={styles.timelineWithoutIconTim}>
      <div className={styles.item}>
        <div className={styles.line}>
          <div className={styles.circle}>
            <div className={styles.shapeRounded} />
          </div>
          <div className={styles.line1}>
            <div className={styles.shapeSharp} />
          </div>
        </div>
        <div className={styles.context}>
          <div className={styles.date}>{new Date(timestamp).toLocaleString('sv-SE', { year: 'numeric',  month: '2-digit', day: '2-digit',hour: '2-digit', minute: '2-digit',second: '2-digit'})}</div>
          <div className={styles.event}>{event}</div>
          {expected && <div className={styles.description}>Expected: {expected}</div>}
          {details && <div className={styles.description}>Details: {details}</div>}
          {!details && <div className={styles.description}>Estimated: {estimated ? estimated : "N/A"}</div>}
          <div className={resolved ? styles.description3 : styles.description2}>Resolved: 
          {resolved !== null ? new Date(resolved).toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit',minute: '2-digit',  second: '2-digit' }) 
                             : "N/A"}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
