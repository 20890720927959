import React, { useState, useEffect, useMemo } from "react";
import SearchAndFilter from "../../components/Visibility/SearchAndFilter/SearchAndFilter";
import { useOutletContext, useSearchParams, useLocation, useNavigate} from "react-router-dom";

const containerTypeDictionary = {};
containerTypeDictionary["Container"] = ["PV", "PW", "PH", "CS", "BK", "HC", "DC", "FC", "HC", "OS"];
containerTypeDictionary["Tank"] = ["TK", "TD", "TH"];
containerTypeDictionary["Flat"] =["FR"];
containerTypeDictionary["Open top"] = ["OT", "UT"];
containerTypeDictionary["Reefer"] = ["RF","HR"];
containerTypeDictionary["Trailer"] = ["ST", "MT"];
containerTypeDictionary["Roll trailer"] = ["PT"];

const Equipments = () => {
  const [allEquipmentsData, setAllEquipmentsData] = useState([]);
  const [shouldResetFilters, setShouldResetFilters] = useState(false); 
  const [filterConditions, setFilterConditions] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filteredEquipmentsData, setFilteredEquipmentsData] = useState([]);
 
  const {onDataUpdate, onResetSelectedFeature, setQueryData} =  useOutletContext();
  const [searchParams] = useSearchParams(); 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEquipmentsData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ITEMS_JSON}/equipments.json`
        );
        const data = await response.json();
        setAllEquipmentsData(data.features); 
        if(searchParams.get('id')){          
          setQueryData(data, 1);   
          }       
      } catch (error) {
        console.error("Error fetching bookings data:", error);
      }
    };
   
    fetchEquipmentsData();
  }, []);

  const getNumberOfDaysFromLastPositionUpdate = (lastUpdateDate) =>{        
    var lastUpdateDateTime = new Date(lastUpdateDate);   
    var currentDate = new Date(new Date().toLocaleString());

    return Math.round((currentDate - lastUpdateDateTime) / (1000 * 3600 *24));
  }

  const filterByEquipmentType = (data, selectedTypes) => {  
    return data.features.filter(feature =>
      selectedTypes.includes(feature.properties.resourceType)
    );
  };
  const filterByEquipmentOwner = (data, slectedOwner) => {
    return data.features.filter(feature =>
      feature?.properties?.equipmentOwner && slectedOwner.includes(feature.properties.equipmentOwner)
    );
  };
  const filterByLastPopsitionUpdate = (data, selectedTimeIntervals) =>{
    let resultList = [];
    for(let interval of selectedTimeIntervals){
      if(interval === "0 - 30 days"){
        let tempResult = data.features.filter(feature => getNumberOfDaysFromLastPositionUpdate(feature.properties.lastPositionUpdate) < 30);
        resultList.push(...tempResult);        
      }

      if(interval === "30 - 90 days"){
        let tempResult = data.features.filter(feature => getNumberOfDaysFromLastPositionUpdate(feature.properties.lastPositionUpdate) >= 30 && getNumberOfDaysFromLastPositionUpdate(feature.properties.lastPositionUpdate) < 90);
        resultList.push(...tempResult);      
      }

      if(interval === "90+ days"){
        let tempResult = data.features.filter(feature => getNumberOfDaysFromLastPositionUpdate(feature.properties.lastPositionUpdate) >= 90);
        resultList.push(...tempResult);        
      }
    }

    return resultList;
  };

  const filterByGPSTracked = (data, gpsTracked) => {
    const gpsTrackedBool = gpsTracked[0] === 'Yes';
    return data.features.filter(feature => feature.properties.isGpsTracked === gpsTrackedBool
    );
  };
  
  const filterByActiveAlert = (data, isActiveAlert) => {
    const isActiveAlertString = isActiveAlert[0] === 'Yes' ? 'true' : 'false';
    return data.features.filter(feature =>
      feature?.properties?.isActiveAlert && feature.properties.isActiveAlert === isActiveAlertString
    );
  };
  
  const memoizedFilteredData = useMemo(() => {
    let filteredData = { type: 'FeatureCollection', features: [...allEquipmentsData] };   
    const checkboxesArray = Object.values(filterConditions.checkboxes || {});    

    const EquipmentTypesCategories = checkboxesArray
    .filter(cb => cb.domain === 'ResourceType' && cb.checked)
    .map(cb => cb.text); 

    const equipmentOwnersCategories = checkboxesArray
    .filter(cb => cb.domain === 'EquipmentOwner' && cb.checked)
    .map(cb => cb.text); 

    const activeAlerts = checkboxesArray
      .filter(cb => cb.domain === 'ActiveAlert' && cb.checked)
      .map(cb => cb.text);

    const lastUpdateFilters = checkboxesArray
    .filter(cb => cb.domain === 'LastPositionUpdate' && cb.checked)
    .map(cb => cb.text);    

    const gpsTracked = checkboxesArray
      .filter(cb => cb.domain === 'GPSTracked' && cb.checked)
      .map(cb => cb.text);

    const equipmentTypes= [];
     Object.keys(containerTypeDictionary).forEach(function(key){        
       ;        if(EquipmentTypesCategories.includes(key) == true){   
            equipmentTypes.push(...containerTypeDictionary[key]);
        }
    });   
    
    if(equipmentTypes.length>0){
        filteredData.features = filterByEquipmentType(filteredData, equipmentTypes);   
       
    }  

    if(equipmentOwnersCategories.length>0){
      filteredData.features = filterByEquipmentOwner(filteredData, equipmentOwnersCategories);        
    }  
  
    if (activeAlerts.length > 0) {
      filteredData.features = filterByActiveAlert(filteredData, activeAlerts);
    }
    if(lastUpdateFilters.length>0){
      filteredData.features = filterByLastPopsitionUpdate(filteredData, lastUpdateFilters);
    }
    if (gpsTracked.length > 0) {
      filteredData.features = filterByGPSTracked(filteredData, gpsTracked);
    }    
    return filteredData;
  }, [allEquipmentsData, filterConditions]);

  useEffect(() => {
    setFilteredEquipmentsData(memoizedFilteredData);
    onDataUpdate(memoizedFilteredData);
  }, [memoizedFilteredData]);

  const onFilterApply = (filters) => {
    setFilterConditions(filters);    
    onResetSelectedFeature();
  };

  const onFilterReset = () => {   
    setFilterConditions({});
    setShouldResetFilters(true);
    setFilteredEquipmentsData(allEquipmentsData);
    onDataUpdate(allEquipmentsData);
  };

  const onResetSearch = () => {
    setSearchText('');
    onDataUpdate(filteredEquipmentsData);
  }; 

  const onSearch = (searchText) => {
    navigate(`${location.pathname}`); 
    setSearchText(searchText);       
    onResetSelectedFeature();
    if (searchText.trim() === '') {
      onDataUpdate(memoizedFilteredData);
    } else {
      const trimmedSearchText = searchText.trim();
      const tempFeatures = allEquipmentsData.filter(equipment => equipment.properties.equipmentNumber === trimmedSearchText);     
      const searchData = {
        type: 'FeatureCollection',
        features: tempFeatures
      };
      onDataUpdate(searchData);
    }    
  }; 

  return (
    <div>
      <SearchAndFilter
        shouldResetFilters={shouldResetFilters}
        setShouldResetFilters={setShouldResetFilters}
        selectedItem={1}
        onSearch={onSearch}
        onFilterApply={onFilterApply}
        onFilterReset={onFilterReset}
        onResetSearch={onResetSearch}
        customerList={[]}
      />
    </div>
  );
};

export default Equipments;
