
 
// Msal Configurations
export const msalConfig = {
  auth: {
    authority: process.env.REACT_APP_ENTRA_AUTHORITY,
    //dev clientId
    clientId: process.env.REACT_APP_ENTRA_APPLICATIONID,
    //prod clientId:
    //clientId: '40fea402-4c86-4bb9-b4b6-656b7dd41eef',
    redirectUri: '/'

    //clientId:  `${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}`, // This is the ONLY mandatory field that you need to supply.
    //authority: `https://login.microsoftonline.com/${process.env["REACT_APP_AAD_APP_TENANT_ID"]}`, // Defaults to "https://login.microsoftonline.com/common"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};


