import React, { useState, useEffect, useRef } from 'react';
import Checkbox from "../../Shared/Checkbox";
import FilterFooter from "../../Shared/FilterFooter";
import styles from "./Filter.module.css";

const TransportFilter = ({ closeFilter, onApplyAll, appliedFilters }) => {

    const defaultCheckboxes = {
      1: { text: "Vessel", checked: false },
      2: { text: "Barge", checked: false },
      3: { text: "Train", checked: false },
      4: { text: "Truck", checked: false },
      5: { text: "In transit", checked: false },
      6: { text: "At terminal", checked: false },
      7: { text: "Yes", checked: false },
      8: { text: "No", checked: false },
      9: { text: "0 - 30 days", checked: false },
      10: { text: "30 - 90 days", checked: false },
      11: { text: "90+ days", checked: false },
    };
  
    const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  
    useEffect(() => {
      if (appliedFilters) {
        setCheckboxes({ ...defaultCheckboxes, ...appliedFilters.checkboxes });
      }
    }, [appliedFilters]);
  
    const clearAll = () => {
      setCheckboxes({ ...defaultCheckboxes });
    };
  
    const applyAll = () => {
      onApplyAll({ checkboxes, selectedCustomer:null });
      closeFilter();
    };
  
    const toggleCheckbox = (key) => {
      setCheckboxes({
        ...checkboxes,
        [key]: {
          ...checkboxes[key],
          checked: !checkboxes[key].checked
        }
      });
    };
    return (
      <div className={styles.sidebar}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>Filters</div>
            <img className={styles.closeIcon} alt="" src="/close.svg" onClick={closeFilter} />
          </div>
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Vehicle type</div>
            <div className={styles.checkboxes}>
              <Checkbox checkboxText={checkboxes[1].text} checked={checkboxes[1].checked} onChange={() => toggleCheckbox(1)} />
              <Checkbox checkboxText={checkboxes[2].text} checked={checkboxes[2].checked} onChange={() => toggleCheckbox(2)} />
              <Checkbox checkboxText={checkboxes[3].text} checked={checkboxes[3].checked} onChange={() => toggleCheckbox(3)} />
              <Checkbox checkboxText={checkboxes[4].text} checked={checkboxes[4].checked} onChange={() => toggleCheckbox(4)} />
            </div>
          </div>
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Transport status</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[5].text} checked={checkboxes[5].checked} onChange={() => toggleCheckbox(5)} />
              <Checkbox checkboxText={checkboxes[6].text} checked={checkboxes[6].checked} onChange={() => toggleCheckbox(6)} />
            </div>
          </div>
   
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Active alert</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[7].text} checked={checkboxes[7].checked} onChange={() => toggleCheckbox(7)} />
              <Checkbox checkboxText={checkboxes[8].text} checked={checkboxes[8].checked} onChange={() => toggleCheckbox(8)} />
            </div>
          </div>
          
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Last update</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[9].text} checked={checkboxes[9].checked} onChange={() => toggleCheckbox(9)} />
              <Checkbox checkboxText={checkboxes[10].text} checked={checkboxes[10].checked} onChange={() => toggleCheckbox(10)} />
              <Checkbox checkboxText={checkboxes[11].text} checked={checkboxes[11].checked} onChange={() => toggleCheckbox(11)} />
            </div>
          </div>
        </div>
        <FilterFooter onClearAll={clearAll} onClose={closeFilter} onApply={applyAll} />
      </div>
    );
  }
  export default TransportFilter;
  