import React, { useState, useEffect } from 'react';
import styles from './SearchAndFilter.module.css';
import BookingsFilter from '../Filters/BookingsFilter';
import EquipmentFilter from '../Filters/EquipmentFilter';
import TransportFilter from '../Filters/TransportFilter';
import TerminalFilter from '../Filters/TerminalFilter';
import Button from '../../Shared/Button';

const SearchAndFilter = ({ shouldResetFilters, setShouldResetFilters, selectedItem, onSearch, onFilterApply, onFilterReset, onResetSearch, customerList }) => {
  const [filterCount, setFilterCount] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});

  const tabs = {
    0: { labelText: 'Booking Number', FilterComponent: BookingsFilter },
    1: { labelText: 'Equipment Number', FilterComponent: EquipmentFilter },
    2: { labelText: 'Vehicle ID', FilterComponent: TransportFilter },
    3: { labelText: 'Terminal name', FilterComponent: TerminalFilter },
  };

  useEffect(() => {
    if (shouldResetFilters) {
      setFilterCount(0);
      setShowFilters(false);
      setSearchText('');
      setAppliedFilters({});
      setShouldResetFilters(false);
    }
  }, [shouldResetFilters, setShouldResetFilters]);

  const handleApplyAll = (filters) => {
    setAppliedFilters(filters);
    onFilterApply(filters);
    const count = Object.values(filters.checkboxes).filter(checkbox => checkbox.checked).length + (filters.selectedCustomer ? 1 : 0);
    setFilterCount(count);
    closeFilter();
  };

  const closeFilter = () => {       
    setShowFilters(false);
  };

  const clearFilters=(filters) =>{   
    setFilterCount(0);
    setAppliedFilters(filters);
    onFilterReset(filters);   
  }

  const handleFilterClick = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const resetSearch = ()=>{
    onResetSearch();
    setSearchText("");
  };

  const currentTab = tabs[selectedItem] || {};
  const FilterComponent = currentTab.FilterComponent;

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <img className={styles.iconLeft} alt="" src="/icons/icons--search3.svg" />
        <input
          type="text"
          placeholder={currentTab.labelText}
          value={searchText}
          onChange={handleSearchTextChange}
          className={styles.textInput}
        />
        <div onClick={resetSearch}>
          <img className={styles.iconLeft} alt="" src="/close.svg" />
        </div>
      </div>
      <Button text="Search" color="blue" onClick={() => onSearch(searchText)} />
      <Button text={`Filters ${filterCount > 0 ? `(${filterCount})` : ''}`} color="white" icon="/icons/icons--filter.svg" iconPosition="left" onClick={handleFilterClick} />
      {showFilters &&
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 123, 255, 0.3)',
            zIndex: 100
          }}
        ></div>
      }
      {showFilters && FilterComponent && <FilterComponent closeFilter={closeFilter} onApplyAll={handleApplyAll} appliedFilters={appliedFilters} clearFilters={clearFilters} customerList={customerList} />}
    </div>
  );
};

export default SearchAndFilter;
