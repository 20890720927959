import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,  
  useNavigate
} from "react-router-dom";
import Visibility from "./pages/Visibility/Visibility";
import { useEffect, useState } from "react";
import SidebarMenu from "./components/SidebarMenu/SidebarMenu";
import Bookings from "./pages/Visibility/Bookings";
import Equipments from "./pages/Visibility/Equipments";

import { AuthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;  
  
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }       

  }, [action, pathname]);

  useMsalAuthentication(InteractionType.Redirect);

  return (
    <AuthenticatedTemplate>
      <div style={{ display: 'flex' }}>
        <SidebarMenu />
        <div style={{ flex: 1 }}>
           <Routes>
            <Route path="*" element={<Navigate replace to="Visibility" />} />
            <Route path="Visibility" element={<Visibility />}>             
              <Route exact path="Bookings" element={<Bookings/>}/>
              <Route exact path="Equipments" element={<Equipments/>}/>             
            </Route>
          </Routes>         
        </div>
      </div>
    </AuthenticatedTemplate>
  );
}
export default App;
                    