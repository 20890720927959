import React, {
  useRef,
  useCallback,
  useState,
  Suspense,
  useEffect,
  useLayoutEffect,
} from "react";
import { Map, Source, Layer, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import TopMenu from "../../components/Visibility/TopMenu/TopMenu.js";
import styles from "./Visibility.module.css";
import BookingCard from "../../components/Visibility/Cards/Booking/BookingCard.js";
import EquipmentCard from "../../components/Visibility/Cards/Equipment/EquipmentCard.js";
import {
  getBookingGpsTrace,
  getEquipmentGpsTrace,
  getBookingRoute,
  getBookingTemperatureAlertTrace,
  getBookingSetPointAlertTrace,
} from "../../apiServices/digitalTwinApiService.js";
import currentLocationPin from "../../iconRoute/currentlocation.svg";
import locationPin from "../../iconRoute/Location.svg";
import collectionPin from "../../iconRoute/Collection.svg";
import deliveryPin from "../../iconRoute/Delivery.svg";
import terminalPin from "../../iconRoute/Terminal.svg";
import { convertDateTime } from "../../helpers/mappers.js";
import Checkbox from "../../components/Shared/Checkbox";
import {
  initialViewport,
  maxBounds,
  clusterCirclePaint,
  unclusteredLayout,
  clusterCountLayout,
  registerEventHandlers,
  removeEventHandlers,
  loadImage,
} from "./MapConfig.js";
import {
  generateSpiderLegParams,
  popupOffsetForSpiderLeg,
  createSpiderLegHtmlContent,
  calculateMedianValue,
  createCustomMarkerElement,
} from "../../helpers/spiderfyingHelpers.js";
import {
  useNavigate,
  useLocation,
  Outlet,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { EquipmentsHeader, BookingsHeader } from "../../helpers/constants.js";
import Bookings from "./Bookings.js";
import Equipments from "./Equipments.js";

const Visibility = () => {
  const mapRef = useRef(null);
  const previousMarkers = useRef([]);
  const previousIdSearchValue = useRef(null);
  const selectedItemId = useRef(null);
  const mapRefData = useRef(null);
  const [selectedItem, setSelectedItem] = useState();
  const [mapData, setMapData] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [gpsData, setGpsData] = useState(null);
  const [itemRoute, setItemRoute] = useState(null);
  const [rawRoute, setRawItemRoute] = useState(null);
  const [setPointAlert, setAlert] = useState(null);
  const [temperatureAlert, setTemperatureAlert] = useState(null);

  const [alerts, setAlerts] = useState(null);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [showClusters, setShowClusters] = useState(true);
  const [showTerminals, setShowTerminals] = useState(false);
  const [terminalsData, setTerminalsData] = useState(null);
  const [geofenceData, setGeofenceData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    removeSpiderMarkers();
  }, [mapData, selectedItem]);

  useEffect(() => {
    const fetchTerminalsData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ITEMS_JSON}/terminals.json`
        );
        const data = await response.json();
        setTerminalsData(data);
      } catch (error) {
        console.error("Error fetching terminals data:", error);
      }
    };
    fetchTerminalsData();
  }, []);

  const determineIconType = (item) => {
    switch (item.addressType) {
      case "Collection":
        return {
          icon: "collectionPin",
          size: 0.16,
          color: "#ff0000",
          opacity: 1,
        };
      case "Swap Container":
        return {
          icon: "collectionPin",
          size: 0.16,
          color: "#ff0000",
          opacity: 1,
        };
      case "Delivery":
        return {
          icon: "deliveryPin",
          size: 0.16,
          color: "#ff0000",
          opacity: 1,
        };
      case "Current Location":
        return {
          icon: "currentLocationPin",
          size: 0.18,
          color: "#002378",
          opacity: 1,
        };
      default:
        switch (item.locationType) {
          case "Terminal":
            return {
              icon: "terminalPin",
              size: 0.2,
              color: "#002378",
              opacity: 1,
            };
        }
    }
  };

  const formatToGeoJSONData = (routeData) => {
    if (!routeData || routeData.length === 0) {
      return null;
    }

    let geojson = [];
    for (let item of routeData) {
      geojson.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.longitude, item.latitude],
        },
        properties: {
          AddressName: item.addressName,
          AddressType: item.addressType,
          LocationType: item.locationType,
          EventDate: item.plannedStartEventDatetime,
          timestamp: item.timestamp,
          Address: item.addressFull,
          Source: item.source,
          sourceSystem: item.sourceSystem,
          ...determineIconType(item),
        },
      });
    }

    return {
      type: "FeatureCollection",
      features: geojson,
    };
  };

  const getGpsData = async (itemId, selectedItemType) => {
    let traceGpsResponse;
    switch (selectedItemType) {
      case 0: {
        traceGpsResponse = (await getBookingGpsTrace(itemId)).data;
        break;
      }
      case 1: {
        traceGpsResponse = (await getEquipmentGpsTrace(itemId)).data;
        break;
      }
    }
    const geojson = formatToGeoJSONData(traceGpsResponse);
    setGpsData(geojson);
    return traceGpsResponse;
  };

  const getSetPointAlert = async (itemId, selectedItemType) => {
    let setPointAlertResponse;
    switch (selectedItemType) {
      case 0: {
        setPointAlertResponse = (await getBookingSetPointAlertTrace(itemId))
          .data;
        break;
      }
    }
    setAlert(setPointAlertResponse);
    return setPointAlertResponse;
  };

  const getTemperatureAlert = async (itemId, selectedItemType) => {
    let temperatureAlertResponse;
    switch (selectedItemType) {
      case 0: {
        temperatureAlertResponse = (
          await getBookingTemperatureAlertTrace(itemId)
        ).data;
        break;
      }
    }

    setTemperatureAlert(temperatureAlertResponse);
    return temperatureAlertResponse;
  };

  const getRouteData = async (itemId, selectedItemType) => {
    let routeData;
    switch (selectedItemType) {
      case 0: {
        routeData = (await getBookingRoute(itemId)).data;
        break;
      }
      case 1: {
        routeData = (await getBookingRoute(itemId)).data;
        break;
      }
    }

    setRawItemRoute(routeData);
    return routeData;
  };

  const removeSpiderMarkers = () => {
    if (previousMarkers.current.length != 0) {
      previousMarkers.current.forEach((item) => {
        item.remove();
      });
      previousMarkers.current = [];
    }
  };

  const spiderify = (e, map) => {
    var features = map.queryRenderedFeatures(e.point, {
      layers: ["unclustered-points", "clusters"],
    });
    var clusterId = features[0].properties.cluster_id,
      point_count = features[0].properties.point_count,
      clusterSource = map.getSource("bookingClusters");

    // Get all points under a cluster
    clusterSource.getClusterLeaves(
      clusterId,
      point_count,
      0,
      function (err, aFeatures) {
        var spiderLegsParam = generateSpiderLegParams(aFeatures.length);

        if (aFeatures.length < 100) {
          var medianValue = calculateMedianValue(aFeatures);
          var lngLat = new mapboxgl.LngLat(medianValue.lng, medianValue.lat);
          aFeatures.forEach((item, index) => {
            var offset = popupOffsetForSpiderLeg(spiderLegsParam[index]);
            let marker = new mapboxgl.Marker({
              element: createCustomMarkerElement(currentLocationPin),
            })
              .setLngLat(lngLat)
              .setOffset(offset);

            var htmlContent = createSpiderLegHtmlContent(item, selectedItem);
            let popup = new mapboxgl.Popup({
              offset: offset,
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(item.geometry.coordinates)
              .setHTML(htmlContent);
            marker.setPopup(popup);
            marker.addTo(map);

            const markerDiv = marker.getElement();
            markerDiv.addEventListener("mouseenter", () =>
              marker.togglePopup()
            );
            markerDiv.addEventListener("mouseleave", () =>
              marker.togglePopup()
            );
            markerDiv.addEventListener("click", () => {
              handleMarkerClick(item);
            });
            previousMarkers.current.push(marker);
          });
        } else {
          let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true,
          })
            .setLngLat(e.lngLat)
            .setText(
              "Too many points to display! Please zoom in or use filters."
            )
            .addTo(map);
        }
      }
    );
  };

  const handleSpiderfying = async (e, event) => {
    const map = mapRef.current.getMap();
    if (e) {
      if (previousMarkers.current.length == 0) {
        spiderify(event, map);
      } else {
        removeSpiderMarkers();
        spiderify(event, map);
      }
    } else {
      removeSpiderMarkers();
    }
  };

  const loadItemUrlQuery = async (data, itemType) => {
    mapRefData.current = data;
    let itemNumber = searchParams.get("id");
    if (itemNumber) {
      previousIdSearchValue.current = itemNumber;
      console.log(previousIdSearchValue);
      await populateItemCardAndRouteData(itemNumber, itemType);
    }

    selectedItemId.current = itemNumber;
    setSelectedFeatureId(itemNumber);
    setShowHeatmap(false);
  };

  useEffect(() => {
    if (location.pathname.includes(BookingsHeader)) {
      setSelectedItem(0);
    } else if (location.pathname.includes(EquipmentsHeader)) {
      setSelectedItem(1);
    } else {
      setSelectedItem(0);
    }
  }, []);

  const handleMarkerClick = async (e) => {
    var itemNumber = 0;

    switch (selectedItem) {
      case 0: {
        itemNumber = e.properties.bookingNumber;
        previousIdSearchValue.current = itemNumber;
        await populateItemCardAndRouteData(
          e.properties.bookingNumber,
          selectedItem
        );
        break;
      }
      case 1: {
        itemNumber = e.properties.equipmentNumber;
        previousIdSearchValue.current = itemNumber;
        await populateItemCardAndRouteData(
          e.properties.equipmentNumber,
          selectedItem
        );
        break;
      }
    }

    selectedItemId.current = itemNumber;
    setSelectedFeatureId(itemNumber);
    setShowHeatmap(false);   
  };

  const populateItemCardAndRouteData = async (itemId, currentSelectedItem) => {
    var itemNumber = 0;
    var dataSource;
    if (searchParams.get("id")) {
      dataSource = mapRefData.current;
    } else {
      dataSource = mapData;
    }

    switch (currentSelectedItem) {
      case 0: {
        itemNumber = itemId;
        var e = dataSource.features.find(
          (x) => x.properties.bookingNumber == itemNumber
        );

        navigate({
          pathname: location.pathname,
          search: `?${createSearchParams({ id: itemNumber })}`,
        });

        const [gpsTrace, route, setPointAlert, temperatureAlert] =
          await Promise.all([
            getGpsData(itemNumber, currentSelectedItem),
            getRouteData(itemNumber, currentSelectedItem),
            getSetPointAlert(itemNumber, currentSelectedItem),
            getTemperatureAlert(itemNumber, currentSelectedItem),
          ]);

        const gpsLatest = gpsTrace && gpsTrace[gpsTrace.length - 1];

        let lastOnRoute =
          route &&
          route.filter(
            (item) =>
              item?.addressType?.includes("Arrival") ||
              item?.addressType?.includes("Departure")
          );

        let lastPoint = lastOnRoute && lastOnRoute[lastOnRoute.length - 1];

        const currentLocation = {
          longitude: e.geometry.coordinates[0],
          latitude: e.geometry.coordinates[1],
          timestamp: e.properties.timestamp,
          properties: e.properties,
        };

        if (gpsLatest) {
          currentLocation.longitude = gpsLatest.longitude;
          currentLocation.latitude = gpsLatest.latitude;
          currentLocation.sourceSystem = gpsLatest.sourceSystem;
          currentLocation.timestamp = gpsLatest.timestamp;
        } else if (lastPoint) {
          currentLocation.AddressName = lastPoint.addressName;
          currentLocation.longitude = lastPoint.longitude;
          currentLocation.latitude = lastPoint.latitude;
          currentLocation.sourceSystem = lastPoint.source;
          currentLocation.timestamp = lastPoint.plannedStartEventDatetime;
        }

        route.push({
          longitude: currentLocation.longitude,
          latitude: currentLocation.latitude,
          timestamp: currentLocation.timestamp,
          sourceSystem: currentLocation.sourceSystem,
          addressType: "Current Location",
        });

        const geojson = formatToGeoJSONData(route);
        setItemRoute(geojson);
        const map = mapRef.current.getMap();
        const bounds = new mapboxgl.LngLatBounds();
        route.forEach((item) => {
          bounds.extend([item.longitude, item.latitude]);
        });

        map.fitBounds(bounds, {
          padding: 20,
          duration: 2000,
        });
        break;
      }
      case 1: {
        itemNumber = itemId;
        var e = dataSource.features.find(
          (x) => x.properties.equipmentNumber == itemNumber
        );
        navigate({
          pathname: location.pathname,
          search: `?${createSearchParams({ id: itemNumber })}`,
        });
        const gpsTrace = await getGpsData(itemNumber, currentSelectedItem);
        const currentLocation = {
          longitude: e.geometry.coordinates[0],
          latitude: e.geometry.coordinates[1],
          timestamp: e.properties.timestamp,
          properties: e.properties,
        };

        const gpsLatest = gpsTrace && gpsTrace[gpsTrace.length - 1];
        const fullElement = dataSource.features.find(
          (feature) => feature.properties.equipmentNumber === itemNumber
        );

        setAlerts(fullElement.alerts);

        if (gpsLatest) {
          currentLocation.longitude = gpsLatest.longitude;
          currentLocation.latitude = gpsLatest.latitude;
          currentLocation.sourceSystem = gpsLatest.sourceSystem;
          currentLocation.timestamp = gpsLatest.timestamp;
        } else if (fullElement.alerts) {
          let lastKnownPosition = fullElement.alerts[0];
          currentLocation.longitude = lastKnownPosition.Longitude;
          currentLocation.latitude = lastKnownPosition.Latitude;
          currentLocation.sourceSystem = lastKnownPosition.SourceSystem;
          currentLocation.timestamp = lastKnownPosition.LastPositionUpdate;
        }

        const route = [];
        route.push({
          longitude: currentLocation.longitude,
          latitude: currentLocation.latitude,
          timestamp: currentLocation.timestamp,
          sourceSystem: currentLocation.sourceSystem,
          addressType: "Current Location",
        });

        const geojson = formatToGeoJSONData(route);
        setItemRoute(geojson);

        break;
      }
    }
  };

  const handleTerminalClick = async (e) => {
    const clickedTerminal = e?.properties?.GeoJson;
    if (clickedTerminal !== null) {
      try {
        const geoJsonData = JSON.parse(clickedTerminal);
        const isSameTerminal =
          JSON.stringify(geoJsonData) === JSON.stringify(geofenceData);
        if (isSameTerminal) {
          setGeofenceData(null);
        } else {
          var geoFenceJson = {
            type: "FeatureCollection",
            features: geoJsonData.features,
          };
          setGeofenceData(geoFenceJson);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    setShowPopup(false);
  };

  const resetSelectedFeature = () => {   
    selectedItemId.current = null;
    setSelectedFeatureId(null);
    setGpsData(null);
    setItemRoute(null);
    setAlerts(null);
    setAlert(null);
    const map = mapRef.current.getMap();
    map.flyTo({
      center: [initialViewport.longitude, initialViewport.latitude],
      zoom: initialViewport.zoom,
      duration: 2000,
    });
  };

  const onMapLoad = useCallback(() => {
    const map = mapRef.current.getMap();
    loadImage(map, "currentLocationPin", currentLocationPin);
    loadImage(map, "locationPin", locationPin);
    loadImage(map, "collectionPin", collectionPin);
    loadImage(map, "deliveryPin", deliveryPin);
    loadImage(map, "terminalPin", terminalPin);

    registerEventHandlers(map, setPopupInfo, setShowPopup, convertDateTime);
    return () => {
      if (map) {
        removeEventHandlers(map);
      }
    };
  }, []);

  useEffect(() => {   
    setSelectedFeatureId(null);
    setGpsData(null);
    setItemRoute(null);
    setAlerts(null);
    removeSpiderMarkers();
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.flyTo({
        center: [initialViewport.longitude, initialViewport.latitude],
        zoom: initialViewport.zoom,
        duration: 2000,
      });
    }

    if (searchParams.get("id") == undefined || previousIdSearchValue.current) {
      switch (selectedItem) {
        case 0: {
          previousIdSearchValue.current = null;
          navigate(BookingsHeader);
          break;
        }
        case 1: {
          previousIdSearchValue.current = null;
          navigate(EquipmentsHeader);
          break;
        }
      }
    }   
  }, [selectedItem]);

  const onViewportChange = (newViewport) => {
    const boundedViewport = {
      ...newViewport,
      longitude: Math.max(
        Math.min(newViewport.longitude, maxBounds[1][0]),
        maxBounds[0][0]
      ),
      latitude: Math.max(
        Math.min(newViewport.latitude, maxBounds[1][1]),
        maxBounds[0][1]
      ),
      zoom: Math.min(newViewport.zoom, initialViewport.maxZoom),
    };
    setViewport(boundedViewport);
  };

  const resetSpiderfying = () => {
    removeSpiderMarkers();
  };

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <div className={styles.bookings}>
        <TopMenu
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        {
          <Outlet
            context={{
              onDataUpdate: setMapData,
              onResetSelectedFeature: resetSelectedFeature,
              setQueryData: loadItemUrlQuery,
            }}
          />
        }
        {/*(() => {
          switch (selectedItem) {
            case 0: {
              return (
                <Bookings
                  onDataUpdate={setMapData}
                  onResetSelectedFeature={resetSelectedFeature}   
                  setUrl={testFunction}               
                />
              );
            }
            case 1: {
              return (
                <Equipments
                  onDataUpdate={setMapData}
                  onResetSelectedFeature={resetSelectedFeature}
                  setUrl={testFunction}
                />
              );
            }
          }
        })()*/}

        <div className={styles.mapContainer}>
          <div className={styles.checkboxContainer}>
            <Checkbox
              checkboxText="Heatmap"
              checked={showHeatmap}
              onChange={() => setShowHeatmap(!showHeatmap)}
            />
            <Checkbox
              checkboxText="Clusters"
              checked={showClusters}
              onChange={() => setShowClusters(!showClusters)}
            />
            <Checkbox
              checkboxText="Terminals"
              checked={showTerminals}
              onChange={() => setShowTerminals(!showTerminals)}
            />
          </div>
          <Map
            ref={mapRef}
            onLoad={onMapLoad}
            onViewportChange={onViewportChange}
            maxBounds={maxBounds}
            interactive={true}
            interactiveLayerIds={[
              "unclustered-points",
              "route-points",
              "terminals-layer",
              "gps-points",
              "geofence-layer",
            ]}
            initialViewState={initialViewport}
            maxZoom={16}
            onZoom={() => {
              resetSpiderfying();
            }}
            style={{ width: "100%", height: "100%" }}
            mapStyle="mapbox://styles/sskipautonomous/clp8ugvmi000z01o0ag9tfohz"
            mapboxAccessToken="pk.eyJ1Ijoic3NraXBhdXRvbm9tb3VzIiwiYSI6ImNsa2dpOWU4eDAwbWgzZW55YnVtMG93MGEifQ.wXOfN3-rTFdGzJ_OeP1VMw"
            //"pk.eyJ1IjoiZmFrZXVzZXJnaXRodWIiLCJhIjoiY2pwOGlneGI4MDNnaDN1c2J0eW5zb2ZiNyJ9.mALv0tCpbYUPtzT7YysA2g"
            renderWorldCopies={false}
            attributionControl={false}
            onClick={(e) => {
              const features = e.target.queryRenderedFeatures(e.point, {
                layers: ["unclustered-points", "clusters", "terminals-layer"],
              });
              if (features.length == 1) {
                if (features[0].properties.GeoJson) {
                  handleTerminalClick(features[0]);
                } else {
                  handleMarkerClick(features[0]);
                }
              } else {
                handleSpiderfying(features[0], e);
              }
            }}
          >
            <Source id="heatmap-source" type="geojson" data={mapData}>
              <Layer
                id="heatmap-layer"
                type="heatmap"
                layout={{ visibility: showHeatmap ? "visible" : "none" }}
                paint={{
                  // Increase the heatmap weight based on frequency and property magnitude
                  "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["get", "mag"],
                    0,
                    0,
                    6,
                    1,
                  ],
                  // Increase the heatmap color weight weight by zoom level
                  // heatmap-intensity is a multiplier on top of heatmap-weight
                  "heatmap-intensity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    1,
                    9,
                    3,
                  ],
                  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                  // Begin color ramp at 0-stop with a 0-transparancy color
                  // to create a blur-like effect.
                  "heatmap-color": [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    0,
                    "rgba(33,102,172,0)",
                    0.2,
                    "rgb(103,169,207)",
                    0.4,
                    "rgb(209,229,240)",
                    0.6,
                    "rgb(253,219,199)",
                    0.8,
                    "rgb(239,138,98)",
                    1,
                    "rgb(178,24,43)",
                  ],
                  // Adjust the heatmap radius by zoom level
                  "heatmap-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    2,
                    9,
                    20,
                  ],
                  // Transition from heatmap to circle layer by zoom level
                  "heatmap-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    7,
                    1,
                    9,
                    0,
                  ],
                }}
              />
            </Source>
            <Source id="terminals-source" type="geojson" data={terminalsData}>
              <Layer
                id="terminals-layer"
                type="circle"
                layout={{ visibility: showTerminals ? "visible" : "none" }}
                paint={{
                  "circle-radius": 4,
                  "circle-color": "#717171",
                }}
              />
            </Source>
            {geofenceData && (
              <Source id="geofence-source" type="geojson" data={geofenceData}>
                <Layer
                  id="geofence-layer"
                  type="fill"
                  source="geofence-source"
                  paint={{
                    "fill-color": "#0000FF",
                    "fill-opacity": 0.5,
                  }}
                />
              </Source>
            )}
            {!searchParams.get("id") && (
              <Source
                id="bookingClusters"
                type="geojson"
                data={mapData}
                cluster={true}
                //clusterMaxZoom={14}
                clusterRadius={50}
              >
                <Layer
                  id="clusters"
                  type="circle"
                  source="bookingClusters"
                  filter={
                    selectedFeatureId
                      ? ["==", ["get", "id"], selectedFeatureId]
                      : ["has", "point_count"]
                  }
                  layout={{ visibility: showClusters ? "visible" : "none" }}
                  paint={clusterCirclePaint}
                />
                <Layer
                  id="cluster-count"
                  type="symbol"
                  source="bookingClusters"
                  filter={
                    selectedFeatureId
                      ? ["==", ["get", "id"], selectedFeatureId]
                      : ["has", "point_count"]
                  }
                  layout={{
                    "text-field": "{point_count_abbreviated}",
                    "text-font": [
                      "DIN Offc Pro Medium",
                      "Arial Unicode MS Bold",
                    ],
                    "text-size": 14,
                    visibility: showClusters ? "visible" : "none",
                  }}
                />
                <Layer
                  id="unclustered-points"
                  type="symbol"
                  source="bookingClusters"
                  filter={
                    selectedFeatureId
                      ? ["==", ["get", "id"], selectedFeatureId]
                      : ["!", ["has", "point_count"]]
                  }
                  layout={{
                    "icon-image": "currentLocationPin",
                    "icon-size": 0.18,
                    visibility: showClusters ? "visible" : "none",
                  }}
                  paint={{
                    "icon-color": "#002378",
                  }}
                />
              </Source>
            )}
            {gpsData && (
              <Source id="gps-data" type="geojson" data={gpsData}>
                <Layer
                  id="gps-points"
                  type="circle"
                  paint={{
                    "circle-radius": 4,
                    "circle-color": "#4DE7B8",
                  }}
                />
              </Source>
            )}
            {itemRoute && (
              <Source id="route-data" type="geojson" data={itemRoute}>
                <Layer
                  id="route-points"
                  type="symbol"
                  source="route-data"
                  layout={{
                    "icon-image": ["get", "icon"],
                    "icon-size": ["get", "size"],
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                  }}
                  paint={{
                    "icon-color": ["get", "color"],
                    "icon-opacity": ["get", "opacity"],
                  }}
                />
              </Source>
            )}
            {showPopup &&
              popupInfo &&
              popupInfo.latitude &&
              popupInfo.longitude && (
                <Popup
                  latitude={popupInfo.latitude}
                  longitude={popupInfo.longitude}
                  closeButton={false}
                  closeOnClick={false}
                  onClose={() => setShowPopup(false)}
                  anchor="bottom"
                >
                  {popupInfo.displayValue.map((item, i) => (
                    <div style={{ textAlign: "center" }} key={i}>
                      {item}
                    </div>
                  ))}
                </Popup>
              )}
          </Map>
          {selectedFeatureId &&
            (() => {
              switch (selectedItem) {
                case 0:
                  return (
                    <BookingCard
                      itemNumber={selectedFeatureId}
                      alerts={{
                        setPointAlert: setPointAlert,
                        temperatureAlert: temperatureAlert,
                      }}
                      close={() => {
                        resetSelectedFeature();
                        navigate(BookingsHeader);
                        const map = mapRef.current.getMap();
                        map.flyTo({
                          center: [
                            initialViewport.longitude,
                            initialViewport.latitude,
                          ],
                          zoom: initialViewport.zoom,
                          duration: 2000,
                        });
                      }}
                      routeData={rawRoute}
                    />
                  );
                case 1:
                  return (
                    <EquipmentCard
                      itemNumber={selectedFeatureId}
                      alerts={alerts}
                      close={() => {
                        resetSelectedFeature();
                        navigate(EquipmentsHeader);
                        const map = mapRef.current.getMap();
                        map.flyTo({
                          center: [
                            initialViewport.longitude,
                            initialViewport.latitude,
                          ],
                          zoom: initialViewport.zoom,
                          duration: 2000,
                        });
                      }}
                    />
                  );
                default:
                  return null;
              }
            })()}
        </div>
      </div>
    </Suspense>
  );
};

export default Visibility;
