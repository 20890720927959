import React from "react";
import PropTypes from "prop-types";
import styles from "./CardHeader.module.css";
import Tag from "./Tag";

const CardHeader = ({ title, onClose, status, openMsk }) => {
  return (
    <div className={styles.header}>
      <div className={styles.frame}>
        <div className={styles.container}>
          <div className={styles.reference}>{title}</div>
          {status && (
            <img
              className={styles.redirectIcon}
              src="../../urlRedirect.svg"
              onClick={openMsk}
            />
          )}
        </div>
        <div className={styles.tag}>{status && <Tag content={status} />}</div>
      </div>
      <img
        className={styles.closeIcon}
        alt="Close"
        src="../../Close.svg"
        onClick={onClose}
      />
    </div>
  );
};

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.string,
};

export default CardHeader;
