export const getPopupContent = (feature, convertDateTime) => {
    let value = [];    
    if (feature.properties.bookingNumber) {
      value.push(`Customer: ${feature.properties.customerName}`);
      value.push(`Booking: ${feature.properties.bookingNumber}`);
      value.push(`Status: ${feature.properties.bookingStatus}`);
    } else if (feature.properties.AddressType) {
      const timestamp = feature?.properties?.EventDate || feature?.properties?.timestamp;
      const source = feature?.properties?.Source || feature?.properties?.sourceSystem;
      value.push(`Address Name: ${feature?.properties?.AddressName}`);
      value.push(`Address Type: ${feature?.properties?.AddressType}`);
      value.push(`Source: ${source}`);
      value.push(`Timestamp: ${timestamp}`);
    } else if (feature.properties.equipmentNumber) {
      value.push(`Equipment number: ${feature.properties.equipmentNumber}`);
    }else if (feature.properties.FullName || feature.properties.LocationType == "Terminal") {
      const terminalName = feature?.properties?.ShortName || feature?.properties?.AddressName;
      value.push(`${feature.properties.LocationType.replace('_',' ')}: ${terminalName}`);
    }else if (feature.properties.sourceSystem || feature.properties.timestamp) {
      value.push(`Source: ${feature.properties.sourceSystem}`);
      value.push(`Timestamp: ${feature.properties.timestamp}`);
      }
    return value;
  };
  
export const loadImage = (map,imageName, imageItem) => {
    const pinImage = new Image();
    pinImage.onload = () => {
      if (!map.hasImage(imageName)) {
        map.addImage(imageName, pinImage, { sdf: true });
      }
    };
    pinImage.src = imageItem;
  };

  export const registerEventHandlers = (map, setPopupInfo, setShowPopup, convertDateTime) => {
    const showPopup = (e) => {
      const feature = e.features[0];
      if (feature) {
        const content = getPopupContent(feature, convertDateTime);
        setPopupInfo({
          displayValue: content,
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
        });
        setShowPopup(true);
      }
    };


  
    const setCursorPointer = () => map.getCanvas().style.cursor = "pointer";
    const resetCursor = () => map.getCanvas().style.cursor = "";
  
    const layers = ["route-points", "unclustered-points","terminals-layer","gps-points", "geofence-layer"];
    layers.forEach(layer => {
      map.on("mouseenter", layer, (e) => {
        setCursorPointer();
        showPopup(e);
      });
      map.on("mouseleave", layer, () => {
        resetCursor();
        setShowPopup(false);
      });
    });
  };
  
  export const removeEventHandlers = (map) => {
    const layers = ["route-points", "unclustered-points","terminals-layer","gps-points", "geofence-layer"];
    layers.forEach(layer => {
      map.off("mouseenter", layer);
      map.off("mouseleave", layer);
    });
  };
  

export const initialViewport = {
    latitude: 51.94875000234986,
    longitude: 4.431722592813356,
    zoom: 3,
    minZoom: 2,
  };
  
  export const maxBounds = [
    [-180, -85],
    [180, 85],
  ];

  export const clusterCirclePaint = {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "rgba(173, 216, 230, 0.6)", // Light blue with transparency
      50,
      "rgba(135, 206, 250, 0.6)",
      100,
      "rgba(0, 191, 255, 0.6)",
      500,
      "rgba(30, 144, 255, 0.6)",
      1000,
      "rgba(0, 0, 205, 0.6)",
      1500,
      "rgba(0, 0, 139, 0.6)", // Dark blue with transparency
    ],
    "circle-radius": [
      "step",
      ["get", "point_count"],
      20,50,
      25,100,
      30,500,
      35,1000,
      40,1500,
      45, // Largest radius for clusters with 100+ points
    ],
  };

  export const unclusteredLayout = {
    "icon-image": "locationPin",
    "icon-size": 0.11,
    //"circle-color": "#4AE9B8",
    //"circle-radius": 5,
  };

  export const clusterCountLayout = {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 14,
  };