import React, { useState, useEffect } from 'react';
import CardHeader from '../CardHeader';
import CardContent from '../CardContent';
import Card_Tab_Equipment_General from './Card_Tab_Equipment_General';
import Card_Tab_Equipment_Events from './Card_Tab_Equipment_Events';
import Card_Tab_Equipment_Alerts from './Card_Tab_Equipment_Alerts';
import styles from '../Card.module.css';
import { getEquipmentByNumber,getEquipmentEvents } from '../../../../apiServices/digitalTwinApiService';


const EquipmentCard = ({ close, itemNumber,alerts }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tabContent,setTabContent] = useState(null);

  let alertsData = [];
  const getEquipmentEventsForCard = async (equipmentNumber) => {
    try {
      const response = await getEquipmentEvents(equipmentNumber);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
 
  const getEquipment = async (equipmentNumber) => {
    try {
      const response = await getEquipmentByNumber(equipmentNumber);
        return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const generateDetails = (alert) => {
    return `Tracker ${alert.TrackerId} (${alert.SourceSystem})
            Latitude: ${alert.Latitude}
            Longitude: ${alert.Longitude}`;
  };

  useEffect(() => {
    if (itemNumber) {
      Promise.all([
        getEquipment(itemNumber),
        getEquipmentEventsForCard(itemNumber).catch(err => {console.log(err)})
      ])
      .then(([equipment,events]) => 
      {
        const generalData = {  equipmentNumber: equipment.equipmentNumber, equipmentSize: equipment.resourceSize +' ft', equipmentType: equipment.description}; 

        if(alerts){
          alertsData = alerts.map(alert => {
            return {
              timestamp: alert.LastPositionUpdate, 
              event: alert.AlertType,
              details: generateDetails(alert), 
            };
          });
        }
        
        const tabContentPairs = [
          { title: 'General', component: Card_Tab_Equipment_General, data: generalData },
          { title: 'Events', component: Card_Tab_Equipment_Events, data: events },
          { title: 'Alerts', component: Card_Tab_Equipment_Alerts, data: alertsData }
        ];    

        setTabContent(tabContentPairs);
        setIsLoading(false);
      });

    }
    
    
  }, []);




  const handleClose = () => {
    close();
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.card}>
      <CardHeader title={itemNumber} onClose={handleClose} status=""/>     
      <CardContent tabContentPairs={tabContent} />
    </div>
  );
};

export default EquipmentCard;
