import Alert from "../Alert";
import styles from './Card_Tab_Equipment_Alerts.module.css';

const Card_Tab_Equipment_Alerts = ({ data }) => { 
  return (
    <div className={styles.content}>
      {data.map((alert, index) => (
        <Alert
          key={index}
          timestamp={alert.timestamp}
          event={alert.event}
          details={alert.details}
          resolved={alert.resolved}
        />
      ))}
    </div>
  );
};

export default Card_Tab_Equipment_Alerts;
