import React, { useState, useEffect, useRef } from 'react';
import Checkbox from "../../Shared/Checkbox";
import FilterFooter from "../../Shared/FilterFooter";
import styles from "./Filter.module.css";
import { filterDomain } from './FilterDomain';

const EquipmentFilter = ({ closeFilter, onApplyAll, appliedFilters, clearFilters }) => {

    const defaultCheckboxes = {
      1: { text: "Stock", checked: false, domain: filterDomain.TransportStatus },
      2: { text: "In transit", checked: false, domain: filterDomain.TransportStatus },
      3: { text: "Repositioning", checked: false, domain: filterDomain.TransportStatus },
      4: { text: "Container", checked: false, domain: filterDomain.ResourceType },
      5: { text: "Flat", checked: false, domain: filterDomain.ResourceType },
      6: { text: "Open top", checked: false, domain: filterDomain.ResourceType },
      7: { text: "Reefer", checked: false, domain: filterDomain.ResourceType },
      8: { text: "Tank", checked: false, domain: filterDomain.ResourceType },
      9: { text: "Trailer", checked: false, domain: filterDomain.ResourceType },
      10: { text: "Roll trailer", checked: false, domain: filterDomain.ResourceType },
      11: { text: "Samskip Owned", checked: false, domain: filterDomain.EquipmentOwner },
      12: { text: "Shippers Owned", checked: false, domain: filterDomain.EquipmentOwner },
      13: { text: "Yes", checked: false, domain: filterDomain.ActiveAlert },
      14: { text: "No", checked: false, domain: filterDomain.ActiveAlert },
      15: { text: "0 - 30 days", checked: false, domain: filterDomain.LastPositionUpdate },
      16: { text: "30 - 90 days", checked: false, domain: filterDomain.LastPositionUpdate },
      17: { text: "90+ days", checked: false, domain: filterDomain.LastPositionUpdate },
      18: { text: "Yes", checked: false, domain:filterDomain.GPSTracked },
      19: { text: "No", checked: false, domain:filterDomain.GPSTracked }
    };
  
    const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  
    useEffect(() => {
      if (appliedFilters) {
        setCheckboxes({ ...defaultCheckboxes, ...appliedFilters.checkboxes });
      }
    }, [appliedFilters]);
  
    const clearAll = () => {    
      setCheckboxes({ ...defaultCheckboxes });     
      clearFilters({checkboxes: defaultCheckboxes});
    };
  
    const applyAll = () => {
      onApplyAll({ checkboxes, selectedCustomer:null });
      closeFilter();
    };
    const toggleCheckbox = (key) => {      
      switch(key){
        case 13:{
          if(checkboxes[13].checked == true){               
            checkboxes[13].checked == false;
          }       
          break;
        }
        case 14:{
          if(checkboxes[14].checked == true){          
            checkboxes[14].checked == false;
          }        
          break;
        }
        case 18:{
          if(checkboxes[18].checked == true){          
            checkboxes[18].checked == false;
          }        
          break;
        }
        case 19:{
          if(checkboxes[19].checked == true){          
            checkboxes[19].checked == false;
          }        
          break;
        }
      }
          setCheckboxes({
        ...checkboxes,
        [key]: {
          ...checkboxes[key],
          checked: !checkboxes[key].checked        
        }
      });
    };
    
    return (
      <div className={styles.sidebar}>
         <div className={styles.header}>
            <div className={styles.title}>Filters</div>
            <img className={styles.closeIcon} alt="" src="/close.svg" onClick={closeFilter} />
          </div>
        <div className={styles.content}>
          {/*<div className={styles.checkboxGroup}>
            <div className={styles.label}>Equipment status</div>
            <div className={styles.checkboxes}>
              <Checkbox checkboxText={checkboxes[1].text} checked={checkboxes[1].checked} domain={checkboxes[1].domain}  onChange={() => toggleCheckbox(1)} />
              <Checkbox checkboxText={checkboxes[2].text} checked={checkboxes[2].checked} domain={checkboxes[2].domain} onChange={() => toggleCheckbox(2)} />
              <Checkbox checkboxText={checkboxes[3].text} checked={checkboxes[3].checked} domain={checkboxes[3].domain} onChange={() => toggleCheckbox(3)} />
            </div>
    </div>*/}
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Equipment type</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[4].text} checked={checkboxes[4].checked} domain={checkboxes[4].domain} onChange={() => toggleCheckbox(4)} />
              <Checkbox checkboxText={checkboxes[5].text} checked={checkboxes[5].checked} domain={checkboxes[5].domain} onChange={() => toggleCheckbox(5)} />
              <Checkbox checkboxText={checkboxes[6].text} checked={checkboxes[6].checked} domain={checkboxes[6].domain} onChange={() => toggleCheckbox(6)} />
              <Checkbox checkboxText={checkboxes[7].text} checked={checkboxes[7].checked} domain={checkboxes[7].domain} onChange={() => toggleCheckbox(7)} />
              <Checkbox checkboxText={checkboxes[8].text} checked={checkboxes[8].checked} domain={checkboxes[8].domain} onChange={() => toggleCheckbox(8)} />
              <Checkbox checkboxText={checkboxes[9].text} checked={checkboxes[9].checked} domain={checkboxes[9].domain} onChange={() => toggleCheckbox(9)} />
              <Checkbox checkboxText={checkboxes[10].text} checked={checkboxes[10].checked} domain={checkboxes[10].domain} onChange={() => toggleCheckbox(10)} />
            </div>
          </div>

          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Equipment Owner</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[11].text} checked={checkboxes[11].checked} domain={checkboxes[11].domain} onChange={() => toggleCheckbox(11)} />
              <Checkbox checkboxText={checkboxes[12].text} checked={checkboxes[12].checked} domain={checkboxes[12].domain} onChange={() => toggleCheckbox(12)} />
            </div>
    </div>

          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Active alert</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[13].text} checked={checkboxes[13].checked} domain={checkboxes[13].domain} onChange={() => toggleCheckbox(13)} />
              <Checkbox checkboxText={checkboxes[14].text} checked={checkboxes[14].checked} domain={checkboxes[14].domain} onChange={() => toggleCheckbox(14)} />
            </div>
    </div>
          
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Last update</div>
            <div className={styles.checkboxGroup}>
              <Checkbox checkboxText={checkboxes[15].text} checked={checkboxes[15].checked} onChange={() => toggleCheckbox(15)} />
              <Checkbox checkboxText={checkboxes[16].text} checked={checkboxes[16].checked} onChange={() => toggleCheckbox(16)} />
              <Checkbox checkboxText={checkboxes[17].text} checked={checkboxes[17].checked} onChange={() => toggleCheckbox(17)} />
            </div>
          </div>

          <div className={styles.checkboxGroup}>
          <div className={styles.label}>GPS Tracked</div>
          <div className={styles.checkboxGroup}>
            <Checkbox checkboxText={checkboxes[18].text} checked={checkboxes[18].checked} onChange={() => toggleCheckbox(18)} />
            <Checkbox checkboxText={checkboxes[19].text} checked={checkboxes[19].checked} onChange={() => toggleCheckbox(19)} />
          </div>
        </div>

        </div>
        <FilterFooter onClearAll={clearAll} onClose={closeFilter} onApply={applyAll} />
      </div>
    );
  }
  export default EquipmentFilter;
  