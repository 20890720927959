import styles from './Route.module.css';

const Route = ({ fromLocation, toLocation, atd, eta }) => {
  return (
    <div className={styles.route}>
      <div className={styles.line}>
        <div className={styles.from}>
          <div className={styles.iconsAnchor1} />
          <div className={styles.abpHullCt}>{fromLocation}</div>
        </div>
        <img className={styles.iconsLongArrowRight1} alt="" src="../../icons/long-arrow-right.svg" />
        <div className={styles.to}>
          <div className={styles.iconsAnchor1} />
          <div className={styles.abpHullCt}>{toLocation}</div>
        </div>
      </div>
      <div className={styles.line1}>
        <div className={styles.left}>
          <div className={styles.atd}>PTD</div>
          <div className={styles.aug202315301}>{atd}</div>
        </div>
        <div className={styles.right}>
          <div className={styles.atd}>PTA</div>
          <div className={styles.aug202315301}>{eta}</div>
        </div>
      </div>
    </div>
  );
};

export default Route;
