import React, { useState } from 'react';
import TopMenuItem from "./TopMenuItem";
import styles from "./TopMenu.module.css";
import { BookingsHeader, EquipmentsHeader } from '../../../helpers/constants';

const TopMenu = ({ selectedItem, setSelectedItem }) => {

  const menuItems = [
    { text: BookingsHeader, enabled: true },
    { text: EquipmentsHeader, enabled: true }
  ];

  return (
    <div className={styles.topMenuDesktop}>
      <div className={styles.topMemnu}>
        {menuItems.map((item, index) => (
          <TopMenuItem
            key={index}
            text={item.text}
            barActive={selectedItem === index}
            onClick={() => item.enabled && setSelectedItem(index)}
            disabled={!item.enabled}
          />
        ))}
      </div>
    </div>
  );
};

export default TopMenu;
