import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from "./SidebarMenu.module.css";
import SidebarMenuItem from './SidebarMenuItem/SidebarMenuItem';

const SidebarMenu = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(0);

  const menuItems = [
    { route: "/Visibility", icon: "/icons/icons--mapmarker1.svg", label: "Button", enabled: true }
    // { route: "/Exceptions", icon: "/icons/icons--exclamationcircle1.svg", label: "Button", enabled: false },
    // { route: "/Files", icon: "/icons/icons--file1.svg", label: "Button", enabled: false },
    // { route: "/Squares", icon: "/icons/icons--square.svg", label: "Button", enabled: false },
    // { route: "/Ships", icon: "/icons/icons--ship1.svg", label: "Button", enabled: false },
    // { route: "/Trains", icon: "/icons/icons--train1.svg", label: "Button", enabled: false },
    // { route: "/Trucks", icon: "/icons/icons--truck1.svg", label: "Button", enabled: false },
  ];

  useEffect(() => {
    const currentItem = menuItems.findIndex(item => item.route === location.pathname);
    setSelectedItem(currentItem !== -1 ? currentItem : 0);
  }, [location, menuItems]);

  return (
    <div className={styles.sidebarMenu}>
      <img className={styles.logoIcon} alt="" src="/logo.svg" />

      {menuItems.map((item, index) => {
        if (item.enabled) {
          return (
            <Link key={index} to={item.route}>
              <SidebarMenuItem
                isSelected={index === selectedItem}
                iconSrc={item.icon}
                onClick={() => setSelectedItem(index)}
              >
                {item.label}
              </SidebarMenuItem>
            </Link>
          );
        } else {
          return (
            <div key={index} onClick={(e) => e.preventDefault()}>
              {/* The onClick will prevent any interaction */}
              <SidebarMenuItem
                isSelected={false}  // Ensure it's not selected
                iconSrc={item.icon}
                disabled
              >
                {item.label}
              </SidebarMenuItem>
            </div>
          );
        }
      })}
    </div>
  )
};

export default SidebarMenu;
