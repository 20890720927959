import Alert from "../Alert";
import styles from './Card_Tab_Booking_Alerts.module.css';

const Card_Tab_Booking_Alerts = ({ data }) => { 
  return (
    <div className={styles.content}>
      {data.map((alert, index) => (
        <Alert
          key={index}
          timestamp={alert.timestamp}
          event={alert.event}
          expected={alert.expected}
          estimated={alert.estimated}
          resolved={alert.resolved}
        />
      ))}
    </div>
  );
};

export default Card_Tab_Booking_Alerts;
