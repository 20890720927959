export const filterDomain = {
        BookingStatus: "BookingStatus",
        BusinessType: "BusinessType",
        TransportStatus: "TransportStatus",
        ActiveAlert: "ActiveAlert",        
        ResourceType: "ResourceType",       
        LastPositionUpdate: "LastPositionUpdate",
        TerminalType: "TerminalType",
        GPSTracked: "GPSTracked",
        EquipmentOwner: "EquipmentOwner"
}