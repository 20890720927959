import styles from './Card_Tab_Equipment_General.module.css';

const Card_Tab_Equipment_General = ({ data }) => {
    const { equipmentNumber, equipmentSize, equipmentType} = data;
    return (
        <div className={styles.lines}>
            <div className={styles.row}>
                <div className={styles.label3}>Equipment Number:</div>
                <div className={styles.text2}>{equipmentNumber}</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label3}>Equipment size:</div>
                <div className={styles.text2}>{equipmentSize}</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label3}>Equipment type:</div>
                <div className={styles.text2}>{equipmentType}</div>
            </div>
        </div>
    );
};

export default Card_Tab_Equipment_General;
