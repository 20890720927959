import styles from "./TimelineContainer.module.css";

const TimelineContainer = ({
  timestamp,
  action,
  shipmentStatus,
  sourceTimestamp,
  showTimelineWithoutIconTim,
}) => {
  return (
    showTimelineWithoutIconTim && (
      <div className={styles.timelineWithoutIconTim}>
        <div className={styles.item}>
          <div className={styles.line}>
            <div className={styles.circle}>
              <div className={styles.shapeRounded} />
            </div>
            <div className={styles.line1}>
              <div className={styles.shapeSharp} />
            </div>
          </div>
          <div className={styles.context}>
            <div className={styles.date}>{timestamp}</div>
            <div className={styles.event}>{action}</div>
            <div className={styles.event}>{shipmentStatus}</div>
            <div className={styles.description}>{sourceTimestamp}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default TimelineContainer;
