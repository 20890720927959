import styles from "./Button.module.css";

const Button = ({ text, color, icon, iconPosition = 'left', onClick }) => {
  const buttonStyle = color === 'white' ? styles.whiteButton : styles.blueButton;

  return (
    <div className={`${styles.buttonContainer} ${buttonStyle}`} onClick={onClick}>
      <div className={styles.content}>
        {icon && iconPosition === 'left' && <img className={styles.icon} alt="" src={icon} />}
        <div className={styles.text}>{text}</div>
        {icon && iconPosition === 'right' && <img className={styles.icon} alt="" src={icon} />}
      </div>
    </div>
  );
};

export default Button;
