import React, { useState, useEffect, useRef } from 'react';
import Checkbox from "../../Shared/Checkbox";
import FilterFooter from "../../Shared/FilterFooter";
import styles from "./Filter.module.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { filterDomain } from './FilterDomain';

const BookingsFilter = ({ closeFilter, onApplyAll, appliedFilters, clearFilters, customerList }) => {

  const defaultCheckboxes = {
    1: { text: "Awaiting execution", checked: false, domain:filterDomain.BookingStatus },
    2: { text: "In transit", checked: false, domain:filterDomain.BookingStatus },
    3: { text: "Delivered", checked: false, domain:filterDomain.BookingStatus },
    4: { text: "Cancelled", checked: false, domain:filterDomain.BookingStatus },
    5: { text: "Shippers owned", checked: false, domain:filterDomain.BusinessType },
    6: { text: "Samskip owned", checked: false, domain:filterDomain.BusinessType },
    7: { text: "Not started", checked: false, domain:filterDomain.TransportStatus },
    8: { text: "On time", checked: false, domain:filterDomain.TransportStatus },
    9: { text: "Delayed", checked: false, domain:filterDomain.TransportStatus },
    10: { text: "Yes", checked: false, domain:filterDomain.ActiveAlert },
    11: { text: "No", checked: false, domain:filterDomain.ActiveAlert },
    12: { text: "Yes", checked: false, domain:filterDomain.GPSTracked },
    13: { text: "No", checked: false, domain:filterDomain.GPSTracked },
  };

  const typeaheadRef = useRef(null);
  const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  const [selectedCustomer, setSelectedCustomer] = useState(null);  

  useEffect(() => {    
    if (appliedFilters) {
      setCheckboxes({ ...defaultCheckboxes, ...appliedFilters.checkboxes });
      setSelectedCustomer(appliedFilters.selectedCustomer || null);
    }
  }, [appliedFilters]);

  const clearAll = () => {    
    setCheckboxes({ ...defaultCheckboxes });      
    setSelectedCustomer(null);   
    clearFilters({checkboxes: defaultCheckboxes, selectedCustomer});
  };

  const handleCustomerChange = (selected) => {
    console.log(selected);
    setSelectedCustomer(selected);    
  };

  const applyAll = () => {
    onApplyAll({ checkboxes, selectedCustomer });
    closeFilter();
  };

  const handleCloseClick = () =>{
    setSelectedCustomer([]);       
  ;}

  const toggleCheckbox = (key) => {      
    switch(key){
      case 10:{
        if(checkboxes[11].checked == true){               
          checkboxes[11].checked == false;
        }       
        break;
      }
      case 11:{
        if(checkboxes[10].checked == true){          
          checkboxes[10].checked == false;
        }        
        break;
      }
      case 12:{
        if(checkboxes[13].checked == true){          
          checkboxes[13].checked == false;
        }        
        break;
      }
      case 13:{
        if(checkboxes[12].checked == true){          
          checkboxes[12].checked == false;
        }        
        break;
      }
    }
        setCheckboxes({
      ...checkboxes,
      [key]: {
        ...checkboxes[key],
        checked: !checkboxes[key].checked        
      }
    });
  };
  
  return (
    <div className={styles.sidebar}>
       <div className={styles.header}>
          <div className={styles.title}>Filters</div>
          <img className={styles.closeIcon} alt="" src="/close.svg" onClick={closeFilter} />
        </div>
      <div className={styles.content}>
        <div className={styles.checkboxGroup}>
          <div className={styles.label}>Booking status</div>
          <div className={styles.checkboxes}>
            <Checkbox checkboxText={checkboxes[1].text} checked={checkboxes[1].checked} onChange={() => toggleCheckbox(1)} />
            <Checkbox checkboxText={checkboxes[2].text} checked={checkboxes[2].checked} onChange={() => toggleCheckbox(2)} />
            <Checkbox checkboxText={checkboxes[3].text} checked={checkboxes[3].checked} onChange={() => toggleCheckbox(3)} />
            <Checkbox checkboxText={checkboxes[4].text} checked={checkboxes[4].checked} onChange={() => toggleCheckbox(4)} />
          </div>
        </div>
        <div className={styles.checkboxGroup}>
          <div className={styles.label}>Business type</div>
          <div className={styles.checkboxGroup}>
            <Checkbox checkboxText={checkboxes[5].text} checked={checkboxes[5].checked} onChange={() => toggleCheckbox(5)} />
            <Checkbox checkboxText={checkboxes[6].text} checked={checkboxes[6].checked} onChange={() => toggleCheckbox(6)} />
          </div>
        </div>  <div className={styles.checkboxGroup}>
          <div className={styles.label}>GPS Tracked</div>
          <div className={styles.checkboxGroup}>
            <Checkbox checkboxText={checkboxes[12].text} checked={checkboxes[12].checked} onChange={() => toggleCheckbox(12)} />
            <Checkbox checkboxText={checkboxes[13].text} checked={checkboxes[13].checked} onChange={() => toggleCheckbox(13)} />
          </div>
        </div>
        {/*<div className={styles.checkboxGroup}>
          <div className={styles.label}>Booking transport status</div>
          <div className={styles.checkboxGroup}>
            <Checkbox checkboxText={checkboxes[7].text} checked={checkboxes[7].checked} onChange={() => toggleCheckbox(7)} />
            <Checkbox checkboxText={checkboxes[8].text} checked={checkboxes[8].checked} onChange={() => toggleCheckbox(8)} />
            <Checkbox checkboxText={checkboxes[9].text} checked={checkboxes[9].checked} onChange={() => toggleCheckbox(9)} />
          </div>
  </div>*/}
        <div className={styles.label}>Customer</div>
        <div className={styles.typeaheadWrapper}>
          <Typeahead 
          ref={typeaheadRef} 
          id="CustomerDropdown" 
          labelKey={(customerList) => `${customerList.customerName}`}
          options={customerList} 
          placeholder="Select customer"
          style={{ width: '100%' }} 
          selected={selectedCustomer ? selectedCustomer : []}
          onChange={handleCustomerChange}
          filterBy={["customerName"]}/>
          <div className={styles.customArrow}>
            <img src="../../close.svg" alt="Dropdown Close" onClick={handleCloseClick}/>
          </div>
        </div>

        {/* <div className={styles.checkboxGroup}>
          <div className={styles.label}>Active alert</div>
          <div className={styles.checkboxGroup}>
            <Checkbox checkboxText={checkboxes[10].text} checked={checkboxes[10].checked} onChange={() => toggleCheckbox(10)} />
            <Checkbox checkboxText={checkboxes[11].text} checked={checkboxes[11].checked} onChange={() => toggleCheckbox(11)} />
          </div>
        </div> */}
      </div>
      <FilterFooter onClearAll={clearAll} onClose={closeFilter} onApply={applyAll} />
    </div>
  );
}
export default BookingsFilter;
