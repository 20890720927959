import styles from './Card_Tab_Equipment_Events.module.css';
import TimelineContainer from "../TimelineContainer";

const Card_Tab_Equipment_Events = ({ data }) => { 
  return (
    <div className={styles.content}>
      {data.map((event, index) => (
        <TimelineContainer
          key={index}
          timestamp={event.plannedStartEventDatetime}
          action={event.addressName}
          shipmentStatus={event.addressType}
          sourceTimestamp={event.source}
          showTimelineWithoutIconTim
        />
      ))}
      <div className={styles.text}>
        <div className={styles.iconsClockO1} />
        <div className={styles.date2}>No other events to display from the last 14 days</div>
      </div>
    </div>
  );
};

export default Card_Tab_Equipment_Events;
