import { getAllCustomers } from "../../apiServices/digitalTwinApiService";
import React, { useState, useEffect, useMemo } from 'react';
import SearchAndFilter from '../../components/Visibility/SearchAndFilter/SearchAndFilter';
import * as signalR from '@microsoft/signalr';
import { useSearchParams, useOutletContext, useLocation, useNavigate} from "react-router-dom";

const Bookings = () => {
  const [allBookingsData, setAllBookingsData] = useState([]);
  const [filteredBookingsData, setFilteredBookingsData] = useState([]);
  const [filterConditions, setFilterConditions] = useState({});
  const [searchText, setSearchText] = useState('');
  const [shouldResetFilters, setShouldResetFilters] = useState(false);
  const [customers, setCustomers] = useState([]);

  const { onDataUpdate, onResetSelectedFeature, setQueryData } = useOutletContext();  
  const [searchParams] = useSearchParams(); 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookingsData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_ITEMS_JSON}/bookings.json`);
        const data = await response.json();     
        setAllBookingsData(data.features); 
        if(searchParams.get('id'))
          {
            setQueryData(data, 0);          
          }
      } catch (error) {
        console.error("Error fetching bookings data:", error);
      }
    };

    const getCustomerList = async () => {
      if (customers.length === 0) {
        const response = await getAllCustomers();
        const data = response.data;
        setCustomers(data);
      }
    };

    getCustomerList();
    fetchBookingsData();   
  }, []);

  const filterBySelectedCustomers = (data, selectedCustomers) => {
    return data.features.filter(booking => booking?.properties?.customerName && booking.properties.customerName === selectedCustomers[0].customerName);
  };

  const convertBusinessType = (businessType) => {
    switch (businessType) {
      case "Door2Door": {
        return "Samskip owned";
      }
      case "Quay2Quay": {
        return "Shippers owned";
      }
    }
  };

  const filterByBookingStatus = (data, selectedStatuses) => {
    return data.features.filter(feature =>
      feature?.properties?.bookingStatus && selectedStatuses.includes(feature.properties.bookingStatus)
    );
  };

  const filterByBusinessType = (data, selectedTypes) => {
    return data.features.filter(feature =>
      feature?.properties?.businessType && selectedTypes.includes(convertBusinessType(feature.properties.businessType))
    );
  };

  const filterByTransportStatus = (data, selectedStatuses) => {
    return data.features.filter(feature =>
      feature?.properties?.TransportStatus && selectedStatuses.includes(feature.properties.TransportStatus)
    );
  };

  const filterByActiveAlert = (data, isActiveAlert) => {
    return data.features.filter(feature =>
      feature?.properties?.isActiveAlert && feature.properties.ActiveAlert === isActiveAlert[0]
    );
  };
  const filterByGPSTracked = (data, gpsTracked) => {
    const gpsTrackedBool = gpsTracked[0] === 'Yes';
    return data.features.filter(feature => feature.properties.isGpsTracked === gpsTrackedBool
    );
  };

  const memoizedFilteredData = useMemo(() => {
    let filteredData = { type: 'FeatureCollection', features: [...allBookingsData] };  
    
    const checkboxesArray = Object.values(filterConditions.checkboxes || {});
    const bookingStatuses = checkboxesArray
      .filter(cb => cb.domain === 'BookingStatus' && cb.checked)
      .map(cb => cb.text);

    const businessTypes = checkboxesArray
      .filter(cb => cb.domain === 'BusinessType' && cb.checked)
      .map(cb => cb.text);

    const transportStatuses = checkboxesArray
      .filter(cb => cb.domain === 'TransportStatus' && cb.checked)
      .map(cb => cb.text);

    // const activeAlerts = checkboxesArray
    //   .filter(cb => cb.domain === 'ActiveAlert' && cb.checked)
    //   .map(cb => cb.text);

    const gpsTracked = checkboxesArray
      .filter(cb => cb.domain === 'GPSTracked' && cb.checked)
      .map(cb => cb.text);


    const customers = filterConditions.selectedCustomer;

    if (bookingStatuses.length > 0) {
      filteredData.features = filterByBookingStatus(filteredData, bookingStatuses);
    }
    if (businessTypes.length > 0) {
      filteredData.features = filterByBusinessType(filteredData, businessTypes);
    }
    if (transportStatuses.length > 0) {
      filteredData.features = filterByTransportStatus(filteredData, transportStatuses);
    }
    // if (activeAlerts.length > 0) {
    //   filteredData.features = filterByActiveAlert(filteredData, activeAlerts);
    // }
    if (gpsTracked.length > 0) {
      filteredData.features = filterByGPSTracked(filteredData, gpsTracked);
    }
    
    if (Array.isArray(customers) && customers.length > 0) {
      filteredData.features = filterBySelectedCustomers(filteredData, customers);
    }
   
    return filteredData;
  }, [allBookingsData, filterConditions]);


  useEffect(() => {  
    setFilteredBookingsData(memoizedFilteredData);
    onDataUpdate(memoizedFilteredData);
  }, [memoizedFilteredData]);

  const onFilterApply = (filters) => {
    setFilterConditions(filters);
    onResetSelectedFeature();
  };

  const onFilterReset = () => {
    setFilterConditions({});
    setShouldResetFilters(true);
    setFilteredBookingsData(allBookingsData);
    onDataUpdate(allBookingsData);
  };

  const onSearch = (searchText) => {   
    navigate(`${location.pathname}`);
    setSearchText(searchText);
    onResetSelectedFeature();
    if (searchText.trim() === '') {
      onDataUpdate(memoizedFilteredData);
    } else {
      const trimmedSearchText = searchText.trim();
      const tempFeatures = allBookingsData.filter(booking => booking.properties && booking.properties.bookingNumber === trimmedSearchText);
      const searchData = {
        type: 'FeatureCollection',
        features: tempFeatures
      };
      onDataUpdate(searchData);
    }
  };

  const onResetSearch = () => {
    setSearchText('');
    onDataUpdate(filteredBookingsData);
  };

  return (
    <div>
      <SearchAndFilter
        shouldResetFilters={shouldResetFilters}
        setShouldResetFilters={setShouldResetFilters}
        selectedItem={0}
        onSearch={onSearch}
        onFilterApply={onFilterApply}
        onFilterReset={onFilterReset}
        onResetSearch={onResetSearch}
        customerList={customers}
      />
    </div>
  );
};

export default Bookings;
