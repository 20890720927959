import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabNavigator.module.css';

const TabNavigator = ({ tabNames, activeTab, onTabChange, dataForTabs }) => {
  return (
    <div className={styles.tabsDefault}>
      {tabNames.map((tabName, index) => {
        const data = dataForTabs[index];
        const unresolvedAlertsCount = tabName === 'Alerts' && data ? data.filter(alert => !alert.resolved).length : 0;

        return (
          <div 
            className={activeTab === tabName ? styles.piecesTabsActive : styles.piecesTabsInactive} 
            onClick={() => onTabChange(tabName)}
            key={tabName}
          >
            <div className={styles.label}>
              <div className={styles.tabName}>
                {tabName}
                {unresolvedAlertsCount > 0 && (
                  <span className={styles.alertCount}> ({unresolvedAlertsCount})</span>
                )}
              </div>
            </div>
            <div className={activeTab === tabName ? styles.borderActive : styles.borderInactive} />
          </div>
        );
      })}
      <div className={styles.separatorPadding}>
        <div className={styles.separator} />
      </div>
    </div>
  );
};

TabNavigator.propTypes = {
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  dataForTabs: PropTypes.arrayOf(PropTypes.any).isRequired, 
};

export default TabNavigator;
