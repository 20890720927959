import Button from "./Button";
import styles from "./FilterFooter.module.css";

const FilterFooter = ({ onClearAll, onClose, onApply }) => {
  return (
    <div className={styles.footer}>
       <div className={styles.separatorPadding0}>
      <div className={styles.separator} />
    </div>
      <div className={styles.footerContent}>
        <div className={styles.footerLeft} onClick={onClearAll}>
          <div className={styles.footerText}>Clear all</div>
        </div>
        <div className={styles.footerRight}>
          <Button text="Cancel" color="white" onClick={onClose} />
          <Button text="Apply" color="blue" onClick={onApply} />
        </div>
      </div>
    </div>
  );
};

export default FilterFooter;
