var twoPi = Math.PI * 2;
var util = {
  each: eachFn,
  map: mapFn,
  mapTimes: mapTimesFn,
  eachTimes: eachTimesFn,
};

export const calculateMedianValue = (listOfPoints) => {
  var lng = 0,
    lat = 0;
  listOfPoints.forEach((item) => {   
    lng = lng + item.geometry.coordinates[0];
    lat = lat + item.geometry.coordinates[1];
  });

  lng = lng / listOfPoints.length;
  lat = lat / listOfPoints.length;

  return { lng, lat };
};

export function createCustomMarkerElement(imagePath) {   
  const el = document.createElement('div');
  const width = 25;
  const height = 32;
  el.className = 'marker';    
  el.style.backgroundColor = "#002378";  
  el.style.mask = 'url(' + imagePath + ') no-repeat center / contain';    
  el.style.width = `${width}px`;
  el.style.height = `${height}px`;
  el.style.backgroundSize = '100%';  
       
  return el;   
}

export function popupOffsetForSpiderLeg(spiderLeg, offset) {
  var pinOffsetX = spiderLeg.x;
  var pinOffsetY = spiderLeg.y;

  offset = offset || 0;
  return offsetVariant([0, offset], pinOffsetX, pinOffsetY);
}

export function generateSpiderLegParams(count) {
  if (count >= 9) {
    return generateSpiralParams(count);
  } else {
    return generateCircleParams(count);
  }
}

export const createSpiderLegHtmlContent = (item, selectedItem) => {
  switch (selectedItem) {
    case 0: {
      return `<div>Customer: ${item.properties.customerName}</div><div>Booking: ${item.properties.bookingNumber}</div><div>Status:${item.properties.bookingStatus}</div>`;
    }
    case 1: {
      return `<div>Equipment number: ${item.properties.equipmentNumber}</div><div>Type: ${item.properties.resourceType}</div>`;
    }
  }
};

function offsetVariant(offset, variantX, variantY) {
  return [offset[0] + (variantX || 0), offset[1] + (variantY || 0)];
}

function generateSpiralParams(count) {
  var legLength = 15,
    angle = 0;
  return util.mapTimes(count, function (index) {
    var pt;
    angle = angle + (28 / legLength + index * 0.0005);
    pt = {
      x: legLength * Math.cos(angle),
      y: legLength * Math.sin(angle),
      angle: angle,
      legLength: legLength,
      index: index,
    };
    legLength = legLength + (twoPi * 4) / angle;
    return pt;
  });
}

function generateCircleParams(count) {
  var circumference = 25 * (2 + count),
    legLength = circumference / twoPi, // = radius from circumference
    angleStep = twoPi / count;

  return util.mapTimes(count, function (index) {
    var angle = index * angleStep;
    return {
      x: legLength * Math.cos(angle),
      y: legLength * Math.sin(angle),
      angle: angle,
      legLength: legLength,
      index: index,
    };
  });
}

// Utility
function eachFn(array, iterator) {
  var i = 0;
  if (!array || !array.length) {
    return [];
  }
  for (i = 0; i < array.length; i++) {
    iterator(array[i], i);
  }
}

function eachTimesFn(count, iterator) {
  if (!count) {
    return [];
  }
  for (var i = 0; i < count; i++) {
    iterator(i);
  }
}

function mapFn(array, iterator) {
  var result = [];
  eachFn(array, function (item, i) {
    result.push(iterator(item, i));
  });
  return result;
}

function mapTimesFn(count, iterator) {
  var result = [];
  eachTimesFn(count, function (i) {
    result.push(iterator(i));
  });
  return result;
}
