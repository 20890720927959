import React from 'react';
import styles from "./SidebarMenuItem.module.css";

const SidebarMenuItem = ({ isSelected, iconSrc, children, onClick, disabled }) => {
    return (
        <div 
            className={isSelected ? styles.selectedMenuItem : styles.menuItem} 
            onClick={disabled ? null : onClick} 
        >
            <div className={styles.buttonContent}>
                <img className={styles.icon} alt="" src={iconSrc} />
                <div className={styles.text}>{children}</div>
            </div>
        </div>
    );
}

export default SidebarMenuItem;
