import styles from './Card_Tab_Booking_General.module.css';
import TemperatureTrend from "../../Statistics/TemperatureTrend.js";
import React, { useState } from 'react';

const Card_Tab_Booking_General = ({ data }) => {
    const { equipmentNumber, customerName } = data;

    return (
        <div className={styles.lines}>
            <div className={styles.row}>
                <div className={styles.label3}>Equipment number:</div>
                <div className={styles.text2}>{equipmentNumber}</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label3}>Customer:</div>
                <div className={styles.text2}>{customerName}</div>
            </div>
        </div> 
    );
};

export default Card_Tab_Booking_General;
