import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DTAPI_URL,
  headers: {
    'content-type': 'application/json'
  }
});

// customer
export const getAllCustomers = async () => {
  return await axiosInstance.get('/api/Customer/GetAllCustomers');
}
export const getCustomerById = async (customerId) => {
  const params = new URLSearchParams([['id', customerId]]);
  return await axiosInstance.get('/api/Customer/GetCustomerById', { params })
}

//bookings
export const getBookingTemperatureTrace = async (bookingNumber) => {
  const params = new URLSearchParams([['bookingNumber', bookingNumber]]);
  var instance = axiosInstance('/api/Bookings/getBookingTemperatureTrace', { params });
  return await instance;
}
export const getBookingByBookingNumber = async (bookingNumber) => {
  const params = new URLSearchParams([['bookingNumber', bookingNumber]]);
  return await axiosInstance.get('/api/Bookings/GetBookingByBookingNumber', { params });
}
export const getBookingClustersByFilters = async (zoomLevel, jsonBody) => {
  const params = new URLSearchParams([['zoomLevel', zoomLevel]]);
  return await axiosInstance.post('/api/Bookings/GetFilteredBookings', jsonBody, { params });
}
export const getBookingRoute = async (bookingNumber) =>{
  const params = new URLSearchParams([['bookingNumber', bookingNumber]]);
  return await axiosInstance.get('/api/Bookings/GetBookingRoute', {params});
}
export const getBookingGpsTrace = async (bookingNumber) =>{
  const params = new URLSearchParams([['bookingNumber', bookingNumber]]);
  return await axiosInstance.get('/api/Bookings/GetBookingGpsTrace', { params });
};
export const getBookingSetPointAlertTrace = async (bookingNumber) =>{
  const params = new URLSearchParams([['bookingNumber', bookingNumber]]);
  return await axiosInstance.get('/api/Bookings/GetSetPointAlertTraceDocument', { params });
};
export const getBookingTemperatureAlertTrace = async (bookingNumber) =>{
  const params = new URLSearchParams([['bookingNumber', bookingNumber]]);
  return await axiosInstance.get('/api/Bookings/GetTemperatureAlertTraceDocument', { params });
};

//equipment
export const getEquipmentByNumber = async (equipmentNumber) => {
  const params = new URLSearchParams([['equipmentNumber', equipmentNumber]]);
  return await axiosInstance.get('/api/Equipments/GetEquipmentByEquipmentNumber', { params });
}
export const getEquipmentEvents = async (equipmentNumber) => {
  const params = new URLSearchParams([['equipmentNumber', equipmentNumber]]);
  return await axiosInstance.get('/api/Equipments/GetEquipmentEvents', { params })
}
export const getEquipmentGpsTrace = async (equipmentNumber) =>{
  const params = new URLSearchParams([['equipmentNumber', equipmentNumber]]);
  return await axiosInstance.get('/api/Equipments/GetEquipmentGpsTrace', { params });
}

// transport modalities
export const getTransportClustersByZoomLevel = async (zoomLevel) => {
  const params = new URLSearchParams([['zoomLevel', zoomLevel]]);
  return await axiosInstance.get('/api/Modality/GetModalityClusterByZoomLevel', { params })
}

//terminals
export const getTerminalClustersByZoomLevel = async (zoomLevel) => {
  const params = new URLSearchParams([['zoomLevel', zoomLevel]]);
  return await axiosInstance.get('/api/Terminals/GetTerminalsClusterByZoomLevel', { params })
}
export const getTerminalsByName = async(name) =>{
  const params = new URLSearchParams([['shortName', name]]); 
  return await axiosInstance.get('/api/Terminals/GetTerminalsByShortName', {params});
}
