import React, { useState } from 'react';
import TabNavigator from './TabNavigator';
import styles from './CardContent.module.css';

const CardContent = ({ tabContentPairs }) => {
  const [activeTab, setActiveTab] = useState(tabContentPairs[0].title);

  const getContentForTab = (tabName) => {
    const pair = tabContentPairs.find((pair) => pair.title === tabName);
    return pair ? pair.component : null;
  };

  const ActiveContent = getContentForTab(activeTab);
  const activeData = tabContentPairs.find((pair) => pair.title === activeTab)?.data;

  return (
    <div className={styles.content}>
      <TabNavigator 
        tabNames={tabContentPairs.map(pair => pair.title)}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        dataForTabs={tabContentPairs.map(pair => pair.data)}
      />
      {ActiveContent && <ActiveContent data={activeData} />}
    </div>
  );
};

export default CardContent;
