import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'moment';
import 'chartjs-adapter-moment';

const minTemp = -5; 
const maxTemp = 5;  
const drawAcceptableRangePlugin = {
    beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const { scales } = chart;

        if (!scales || !scales.x) {
            return;
        }

        const xScale = scales.x;
        const xScaleWidth = xScale.width;
        const xScaleHeight = xScale.height;
        const xScaleLeft = xScale.left;
        const xScaleBottom = xScale.bottom;

        const startX = xScale.getPixelForValue(minTemp);
        const endX = xScale.getPixelForValue(maxTemp);

        ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
        ctx.fillRect(startX, xScaleBottom, endX - startX, -xScaleHeight);
    },
};

const TemperatureTrend = ({ data }) => {

    const chartData = {
        labels: data.map(d => new Date(d.timestamp).toLocaleString()),
        datasets: [
            {
                label: 'Temperature (°C)',
                data: data.map(d => d.value),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                pointBackgroundColor: 'rgb(75, 192, 192)',
                pointBorderColor: 'rgb(255,255,255)',
                pointHoverBackgroundColor: 'rgb(255,255,255)',
                pointHoverBorderColor: 'rgb(75, 192, 192)'
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    stepSize: 1,  
                    displayFormats: {
                      hour: 'HH:mm', 
                    }
                }
            },
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.parsed.y.toFixed(2) + '°C';
                    }
                }
            },
            drawAcceptableRange: drawAcceptableRangePlugin
        }
    };

    return <Line data={chartData} options={options} />;
};

export default TemperatureTrend;