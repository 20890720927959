import styles from './Tag.module.css';


const Tag = ({content}) => {    
    return (
          <div className={styles.tag1}>
                {content}
          </div>
  );
};

export default Tag;
