import React, { useState, useEffect, useRef } from 'react';
import Checkbox from "../../Shared/Checkbox";
import FilterFooter from "../../Shared/FilterFooter";
import styles from "./Filter.module.css";
import { filterDomain } from './FilterDomain';

const TerminalFilter = ({ closeFilter, onApplyAll, appliedFilters }) => {

    const defaultCheckboxes = {
      1: { text: "Sea terminal", checked: false, domain: filterDomain.TerminalType },
      2: { text: "Inland terminal", checked: false, domain: filterDomain.TerminalType },
      3: { text: "Depot", checked: false, domain: filterDomain.TerminalType }
    };
  
    const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  
    useEffect(() => {
      if (appliedFilters) {
        setCheckboxes({ ...defaultCheckboxes, ...appliedFilters.checkboxes });
      }
    }, [appliedFilters]);
  
    const clearAll = () => {
      setCheckboxes({ ...defaultCheckboxes });
    };
  
    const applyAll = () => {
      onApplyAll({ checkboxes, selectedCustomer:null });
      closeFilter();
    };
  
    const toggleCheckbox = (key) => {
      setCheckboxes({
        ...checkboxes,
        [key]: {
          ...checkboxes[key],
          checked: !checkboxes[key].checked
        }
      });
    };
    return (
      <div className={styles.sidebar}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>Filters</div>
            <img className={styles.closeIcon} alt="" src="/close.svg" onClick={closeFilter} />
          </div>
          <div className={styles.checkboxGroup}>
            <div className={styles.label}>Terminal type</div>
            <div className={styles.checkboxes}>
              <Checkbox checkboxText={checkboxes[1].text} checked={checkboxes[1].checked} domain={checkboxes[1].domain} onChange={() => toggleCheckbox(1)} />
              <Checkbox checkboxText={checkboxes[2].text} checked={checkboxes[2].checked} domain={checkboxes[2].domain} onChange={() => toggleCheckbox(2)} />
              <Checkbox checkboxText={checkboxes[3].text} checked={checkboxes[3].checked} domain={checkboxes[3].domain} onChange={() => toggleCheckbox(3)} />
            </div>
          </div>
        </div>
        <FilterFooter onClearAll={clearAll} onClose={closeFilter} onApply={applyAll} />
      </div>
    );
  }
  export default TerminalFilter;
  