import React, { useEffect, useState } from "react";
import CardHeader from "../CardHeader";
import CardContent from "../CardContent";
import Card_Tab_Booking_General from "./Card_Tab_Booking_General";
import Card_Tab_Booking_Events from "./Card_Tab_Booking_Events";
import Card_Tab_Booking_Alerts from "./Card_Tab_Booking_Alerts";
import Route from "../Route";
import styles from "../Card.module.css";
import {
  getBookingByBookingNumber,
  getBookingTemperatureTrace,
  getBookingSetPointAlertTrace,
} from "../../../../apiServices/digitalTwinApiService";
import { convertDateTime } from "../../../../helpers/mappers.js";

const BookingCard = ({ close, itemNumber, routeData, alerts }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [booking, setBooking] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [tabContent, setTabContent] = useState(null);
  const processRouteData = () => {
    if (routeData && routeData.length !== 0) {
      let firstCollection = null;
      let lastDelivery = null;
      let plannedArrival = null;
      let plannedDeparture = null;
      const dataList = [];
      
      for (const item of routeData) {

        if(item.addressType !== "Collection" && item.addressType !== "Swap Container" && item.addressType !== "Delivery" && item.addressType !== "Current Location" && item.addressType !== null)
        {
          dataList.push({
            timestamp: item?.plannedStartEventDatetime,
            action: item?.addressType,
            shipmentStatus: item?.addressName,
            source: item?.source,
          });
        }

        if (!firstCollection && (item.addressType === "Collection" || item.addressType === "Swap Container")) {
          firstCollection = item;
          plannedDeparture = firstCollection.plannedEndEventDatetime;
        }

        if (item.addressType === "Delivery") {
          lastDelivery = item;
          plannedArrival = lastDelivery.plannedStartEventDatetime;
        }

      }

      const route = {
        fromLocation: firstCollection?.addressName,
        toLocation: lastDelivery?.addressName,
        atd: plannedDeparture,
        eta: plannedArrival,
      };
      setRouteInfo(route);
      return dataList;
    }
  };

  const getBooking = async (bookingNumber) => {
    try {
      const response = await getBookingByBookingNumber(bookingNumber);
      const booking = response.data;
      if (booking) {
        setBooking(booking);
        return booking;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const generateDetails = (alert) => {
    return `BookingNumber: ${alert.bookingNumber}`;
  };
  const processAlerts = (alertType, alerts) => {
    if (alerts && alerts[alertType]) {
      return alerts[alertType].map(alert => ({
        timestamp: alert.startDate || alert.warningDate,
        event: alert.alertType,
        details: generateDetails(alert),
        resolved: alert.resolvedDate,
      }));
    }
    return [];
  };
  useEffect(() => {
    if (itemNumber) {
    let alertsData = [];
      Promise.all([
        processRouteData(routeData),
        getBooking(itemNumber).catch((err) =>
          console.log("Error fetching booking:", err)
        ),
      ]).then(([events, booking]) => {
        const generalData = {
          equipmentNumber: booking?.equipmentNumber,
          customerName: booking?.customerName
        };
        
    if (alerts) {
      alertsData = alertsData.concat(
        processAlerts("setPointAlert", alerts),
        processAlerts("temperatureAlert", alerts)
      );
    }

        const tabContentPairs = [
          {
            title: "General",
            component: Card_Tab_Booking_General,
            data: generalData,
          },
          { title: "Events", component: Card_Tab_Booking_Events, data: events },
          {
            title: "Alerts",
            component: Card_Tab_Booking_Alerts,
            data: alertsData,
            
          },
        ];
        setTabContent(tabContentPairs);
        setIsLoading(false);
      });
    }
  }, []);

  const handleClose = () => {
    close();
  };

  const openMskBooking = () => {   
    let url = `https://outsystems.samskip.com/CA_Order/SingleOrder/${itemNumber}/1`;    
    const newWindow = window.open(url, 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.card}>
      <CardHeader
        title={itemNumber}
        onClose={handleClose}
        status={booking?.bookingStatus}
        openMsk={openMskBooking}
      />
      {routeData.length != 0 && <Route {...routeInfo} />}
      <CardContent tabContentPairs={tabContent} />
    </div>
  );
};

export default BookingCard;
