import React from 'react';
import styles from "./Checkbox.module.css";

const Checkbox = ({ checkboxText, checked = false, onChange }) => {

  const handleCheckboxToggle = () => {
    onChange(); 
  };

  return (
    <div className={styles.checkbox} onClick={handleCheckboxToggle}>
      {checked ? (
        <img className={styles.checkboxIcon} alt="Checked" src="/checkbox1.svg" />
      ) : (
        <div className={styles.checkbox1} />
      )}
      <div className={styles.checkboxText}>
        {checkboxText}
      </div>
    </div>
  );
};

export default Checkbox;
